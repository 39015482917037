import React from "react";
import { graphql } from "gatsby";

export default class extends React.PureComponent {
  componentDidMount() {
    window.location.replace("https://order.eggnbird.com/");
  }
  render() {
    return null;
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
        }
      }
    }
  }
`;
